import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../../components/SEO"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import ContentSection from "../../../components/ContentSection"

const BestInfraStructure = () => {
  return (
    <Box>
      <SEO title="BEST INFRASTURE" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            BEST INFRASTRUCTURE
          </Text>
        </PageHeader>
        <ContentSection
          header="BEST INFRASTRUCTURE"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text sx={{ fontSize: [0, 3] }}>
                WE HAVE THE ROBUST INFRASTRUCTURE WITH SPECIFIC NEEDS AND GOALS
                WHICH INCLUDES RELIABLE HARDWARE, EFFICIENT SOFTWARE, SECURE
                NETWORKS, AND SKILLED PERSONNEL WITH CLOUD COMPUTING PLAYS A KEY
                ROLE FOR SECURITY MEASURES AND DATA BACKUPS AND DISASTER
                RECOVERY PLANS AS CRUCIAL COMPONENTS. WITH OUR REGULAR UPDATES
                AND MONITORING HELP ENSURE OPTIMIAL PERFORMANCE AND ADAPTABILITY
                TO EOLVING TECHNOLOGY TRENDS.
              </Text>
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default BestInfraStructure
